// pusher.js
'use strict';
import {
  authorizer,
  setLanguage,
  clearAuthorizers,
  resetRateLimitHandlers,
  addRetryChannel,
  removeRetryChannel,
} from './pusherAuthorizer.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getIsInServiceWorker } from '../resource/getJsEnvironment.js';

const isOnServiceWorker = getIsInServiceWorker();

/** @type {import('pusher-js').default} */
let Pusher = undefined;

if (isOnServiceWorker) {
  self.importScripts('https://js.pusher.com/8.3.0/pusher.worker.min.js');
  Pusher = self.Pusher;
} else {
  Pusher = require('pusher-js');
}

/** @type {import('pusher-js').default} */
let socket = undefined;

const waitForSocket = async () => {
  while (!socket) {
    await new Promise(resolve => setTimeout(resolve));
  }
  return socket;
};

const authHeaders = { headers: {} };

export const connect = ({
  wssHost,
  wsHost,
  wssPort,
  forceTLS,
  enabledTransports,
  appId = '550591',
  key = 'aad17fe8f682717df2c0',
  cluster = 'ap1',
  activityTimeout = 120000,
  headers,
  authBatchMaxCount = 10,
  onAuth = () => null,
  onEvent = () => null,
} = {}) => {
  onEvent({ name: 'state_change', state: 'initializing' });

  if (socket) {
    if (socket.key === key) {
      onEvent({ name: 'state_change', state: socket.connection.state });
      return socket;
    } else {
      socket.disconnect();
      socket = null;
    }
  }
  resetRateLimitHandlers();
  clearAuthorizers();
  const authUrl = getResourceUrl({
    endpoint: `/pusher/batch-authenticate?app_id=${appId}`,
  });

  authHeaders.headers = headers;
  const config = {
    wssHost,
    wsHost,
    wssPort,
    forceTLS,
    enabledTransports,
    authEndpoint: authUrl.href,
    encrypted: true,
    cluster,
    activityTimeout,
    authDelay: 5000,
    auth: { headers },
    authorizer: (channel, options) =>
      authorizer({
        channel,
        options,
        authHeaders,
        onAuth,
        authBatchMaxCount,
      }),
  };

  const isSupportWebSocket = typeof self !== 'undefined' && 'WebSocket' in self;

  if (isSupportWebSocket) {
    config.disabledTransports = ['xhr_streaming', 'xhr_polling', 'sockjs'];
  }

  socket = new Pusher(key, config);
  socket.onAuth = onAuth; // keep onAuth reference for sync pusher pattern
  onEvent({ name: 'state_change', state: socket.connection.state });
  return socket;
};
export const deleteChannel = name => {
  delete socket.channels.channels[name];
};
export const disconnect = () => {
  socket && socket.disconnect();
  Pusher.instances = [];
  socket = null;
};
export const getSocket = () => socket;
export const setAuthHeaders = ({ headers }) => {
  authHeaders.headers = headers;
};

// will follow default critical channel retry logic in `resource/pusherUtils.js` if `shouldRetry` omitted.
export const subscribe = async ({ channelName, shouldRetry = undefined }) => {
  await waitForSocket();

  if (shouldRetry) addRetryChannel({ channelName });
  else if (shouldRetry === false) removeRetryChannel({ channelName });

  return socket.subscribe(channelName);
};

export const unsubscribe = async name => {
  await waitForSocket();
  socket.unsubscribe(name);
};

export const connectEventListener = callback => {
  if (socket) {
    return socket.connection.bind('state_change', event => {
      return callback(event);
    });
  }
};

export const getConnectState = () => socket && socket.connection.state;

export const getAllChannels = () =>
  socket && socket.allChannels().map(({ name }) => ({ name }));

const setAuthorizerLanguage = setLanguage;

export default {
  connect,
  disconnect,
  getSocket,
  subscribe,
  unsubscribe,
  connectEventListener,
  getConnectState,
  getAllChannels,
  setAuthHeaders,
  deleteChannel,
  setAuthorizerLanguage,
};
